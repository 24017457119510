import {
  wrapRootElement as rootWrapper,
  wrapPageElement as pageWrapper,
} from './root-wrapper';

export const wrapRootElement = rootWrapper;
export const wrapPageElement = pageWrapper;

export const onClientEntry = () => {
  if (process.env.GATSBY_IN_SITE_MAINTENANCE === 'true' && window.location.pathname.indexOf('maintenance') < 0) {
    window.location.href = '/maintenance';
  }
};

export const onRouteUpdate = () => {
  if (process.env.GATSBY_IN_SITE_MAINTENANCE === 'true' && window.location.pathname.indexOf('maintenance') < 0) {
    window.location.href = '/maintenance';
  }
};
