/* eslint-disable max-len */
import React, {
  createContext, useReducer, useMemo, useContext, ReactNode, Dispatch, useEffect,
} from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import { EquipmentCategory } from 'domain/equipment/equipment-category';

export type CompareEquipmentSetItem = {
  name: string, longname: string
}

export type CompareEquipmentSet = {
  [component in EquipmentCategory]: CompareEquipmentSetItem
}

export interface CompareItem {
  timestamp: number;
  buildId: string;
  equipmentSet: CompareEquipmentSet
  image: {
    image: IGatsbyImageData,
    alt: string;
  },
  price: number;
  longname: string;
}

interface AddItemAction {
  type: 'ADD_ITEM';
  payload: CompareItem;
}

interface RemoveItemAction {
  type: 'REMOVE_ITEM';
  payload: CompareItem;
}

interface UpdateItemAction {
  type: 'UPDATE_ITEM';
  payload: CompareItem;
}

interface LoadStoreAction {
  type: 'LOAD_STORE';
  payload: CompareItem[];
}

interface ClearStoreAction {
  type: 'CLEAR_STORE';
}

type CompareAction = AddItemAction | RemoveItemAction | UpdateItemAction | ClearStoreAction | LoadStoreAction;

export const initialState = [];

export const CompareContext = createContext<{
  compareData: CompareItem[],
  dispatch: Dispatch<CompareAction>
} | null>(null);

const compareReducer = (state: CompareItem[], action: CompareAction) => {
  let newState;
  switch (action.type) {
    case 'ADD_ITEM':
      newState = [...state, action.payload];
      break;
    case 'REMOVE_ITEM':
      newState = [...state.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(action.payload),
      )];
      break;
    case 'CLEAR_STORE':
      newState = initialState;
      break;
    case 'LOAD_STORE':
      newState = [...action.payload];
      break;
    default:
      newState = state;
  }
  localStorage.setItem('compareData', JSON.stringify(newState));
  return newState;
};

interface CompareProviderProps {
  children: ReactNode;
}

export const CompareProvider = ({ children }: CompareProviderProps) => {
  const [compareData, dispatch] = useReducer(compareReducer, initialState);

  const { allBuildsDataSet: { nodes } } = useStaticQuery(graphql`
    query CheckPresentData {
      allBuildsDataSet {
        nodes {
          timestamp
          buildId
        }
      }
    }
  `);

  useEffect(() => {
    const storedData = localStorage.getItem('compareData');
    let parsedData = storedData ? JSON.parse(storedData) : initialState;

    if (parsedData?.length) {
      parsedData = parsedData.filter(
        (parsedElement: CompareItem) => !!nodes.find(
          (
            { timestamp, buildId } :
            { timestamp: number, buildId: string },
          ) => timestamp === parsedElement.timestamp && buildId === parsedElement.buildId,
        ),
      );
    }

    dispatch({ type: 'LOAD_STORE', payload: parsedData });
  }, []);

  const contextValue: {
    compareData: CompareItem[];
    dispatch: Dispatch<CompareAction> } = useMemo(() => ({
      compareData,
      dispatch,
    }), [compareData, dispatch]);

  return (
    compareData
      ? <CompareContext.Provider value={contextValue}>{children}</CompareContext.Provider>
      : null
  );
};

export const useCompare = () => {
  const context = useContext(CompareContext);

  if (!context) {
    throw new Error('useCompare must be used within a CompareProvider');
  }

  const { dispatch, compareData } = context;

  const checkCompare = (item: CompareItem) => {
    const isInCompare = compareData.find(
      (compareElement) => JSON.stringify(compareElement) === JSON.stringify(item),
    );
    return !!isInCompare;
  };

  const addToCompare = (item: CompareItem) => {
    if (!checkCompare(item) && compareData.length < 2) {
      dispatch({
        type: 'ADD_ITEM',
        payload: item,
      });
    }
  };

  const removeFromCompare = (item: CompareItem) => {
    dispatch({
      type: 'REMOVE_ITEM',
      payload: item,
    });
  };

  const resetCompare = () => dispatch({
    type: 'CLEAR_STORE',
  });

  const compareItems = useMemo(() => (
    compareData.map((item: CompareItem) => item.buildId)
  ), [compareData]);

  return {
    compareData,
    addToCompare,
    removeFromCompare,
    resetCompare,
    checkCompare,
    compareItems,
  };
};
