import axios from 'axios';
import { PcBuildType } from 'domain/equipment/build-type';

export type CheckoutBodyProps = {
	buildId: string;
	components: {
		componentId: string;
		qty: number;
	}[];
	additionalServicesIds: string[];
	type: PcBuildType;
};

export const getCheckout = async (body: CheckoutBodyProps) => {
	const response = await axios.post(
		process.env.GATSBY_PC_BUILDER_CHECKOUT_ENDPOINT || '',
		body,
	);
	return response;
};
