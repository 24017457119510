import React, { createContext, useCallback, useContext } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

interface StaticImage {
  name: string,
  imgFile: IGatsbyImageData,
}

interface StaticImagesProviderProps {
  children: React.ReactNode,
}

const StaticImagesContext = createContext<StaticImage[]>([] as StaticImage[]);

export const StaticImagesProvider = (props: StaticImagesProviderProps) => {
  const { children } = props;
  const data = useStaticQuery<Queries.StaticImagesQuery>(graphql`
  query StaticImages {
  allFile(filter: {absolutePath: {regex: "/images/"}}) {
    edges {
      node {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}`);

  const value = data.allFile.edges
    .map(({ node: { childImageSharp, name } }) => (
      { name, imgFile: childImageSharp!.gatsbyImageData }
    ));

  return (
    <StaticImagesContext.Provider value={value}>
      {children}
    </StaticImagesContext.Provider>
  );
};

export const useStaticImages = () => {
  const images = useContext(StaticImagesContext);

  const findStaticImage = useCallback((name?: string) => (
    images.filter((image) => image.name === name).map((image) => image.imgFile)
  ), []);

  return { findStaticImage };
};
