exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-konfigurator-pc-index-tsx": () => import("./../../../src/pages/konfigurator-pc/index.tsx" /* webpackChunkName: "component---src-pages-konfigurator-pc-index-tsx" */),
  "component---src-pages-kontakt-index-tsx": () => import("./../../../src/pages/kontakt/index.tsx" /* webpackChunkName: "component---src-pages-kontakt-index-tsx" */),
  "component---src-pages-koszyk-index-tsx": () => import("./../../../src/pages/koszyk/index.tsx" /* webpackChunkName: "component---src-pages-koszyk-index-tsx" */),
  "component---src-pages-maintenance-index-tsx": () => import("./../../../src/pages/maintenance/index.tsx" /* webpackChunkName: "component---src-pages-maintenance-index-tsx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-about-page-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/about-page.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-about-page-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-ile-kosztuje-zlozenie-komputera-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/guide/ile-kosztuje-zlozenie-komputera.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-ile-kosztuje-zlozenie-komputera-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-jak-zlozyc-komputer-do-gier-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/guide/jak-zlozyc-komputer-do-gier.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-jak-zlozyc-komputer-do-gier-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-komputer-gamingowy-a-zastosowania-polprofesjonalne-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/guide/komputer-gamingowy-a-zastosowania-półprofesjonalne.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-komputer-gamingowy-a-zastosowania-polprofesjonalne-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-odstapienie-od-umowy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/odstapienie-od-umowy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-odstapienie-od-umowy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-polityka-prywatnosci-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/polityka-prywatnosci.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-polityka-prywatnosci-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-regulamin-sklepu-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/regulamin-sklepu.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-regulamin-sklepu-mdx" */),
  "component---src-pages-o-nas-index-tsx": () => import("./../../../src/pages/o-nas/index.tsx" /* webpackChunkName: "component---src-pages-o-nas-index-tsx" */),
  "component---src-pages-poradniki-index-tsx": () => import("./../../../src/pages/poradniki/index.tsx" /* webpackChunkName: "component---src-pages-poradniki-index-tsx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-about-page-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/about-page.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-about-page-mdx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-ile-kosztuje-zlozenie-komputera-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/guide/ile-kosztuje-zlozenie-komputera.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-ile-kosztuje-zlozenie-komputera-mdx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-jak-zlozyc-komputer-do-gier-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/guide/jak-zlozyc-komputer-do-gier.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-jak-zlozyc-komputer-do-gier-mdx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-komputer-gamingowy-a-zastosowania-polprofesjonalne-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/guide/komputer-gamingowy-a-zastosowania-półprofesjonalne.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-guide-komputer-gamingowy-a-zastosowania-polprofesjonalne-mdx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-odstapienie-od-umowy-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/odstapienie-od-umowy.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-odstapienie-od-umowy-mdx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-polityka-prywatnosci-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/polityka-prywatnosci.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-polityka-prywatnosci-mdx" */),
  "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-regulamin-sklepu-mdx": () => import("./../../../src/pages/poradniki/{mdx.frontmatter__slug}.tsx?__contentFilePath=/builds/pc-builder-app/pc-builder/src/data/pages/regulamin-sklepu.mdx" /* webpackChunkName: "component---src-pages-poradniki-mdx-frontmatter-slug-tsx-content-file-path-src-data-pages-regulamin-sklepu-mdx" */),
  "component---src-pages-porownywarka-index-tsx": () => import("./../../../src/pages/porownywarka/index.tsx" /* webpackChunkName: "component---src-pages-porownywarka-index-tsx" */),
  "component---src-pages-sukces-[cart]-tsx": () => import("./../../../src/pages/sukces/[cart].tsx" /* webpackChunkName: "component---src-pages-sukces-[cart]-tsx" */),
  "component---src-pages-zestawy-komputerowe-builds-data-set-build-id-tsx": () => import("./../../../src/pages/zestawy-komputerowe/{buildsDataSet.buildId}.tsx" /* webpackChunkName: "component---src-pages-zestawy-komputerowe-builds-data-set-build-id-tsx" */)
}

