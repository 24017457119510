import { CartItemDTO } from 'domain/equipment/cart';
import { IGatsbyImageData } from 'gatsby-plugin-image';

export const getItemsValue = (
  items: readonly CartItemDTO<IGatsbyImageData>[],
) => items.reduce((prev, curr) => prev + curr.price, 0);

export const mapCartItem = (item: CartItemDTO<IGatsbyImageData>) => ({
  item_id: item.componentId || item.serviceId,
  item_name: item.name,
  price: item.price,
  quantity: 1,
});

type ga4EventName = 'add_to_cart' | 'remove_from_cart' | 'begin_to_checkout' | 'purchase';

export type ga4Item = {
  item_id?: string;
  item_name: string;
  price: number;
  quantity: number;
}

type ga4Data = {
  transaction_id?: string;
  currency: string;
  value: number;
  items?: ga4Item[];
}

export const reportAnEvent = (
  eventName: ga4EventName,
  items: readonly CartItemDTO<IGatsbyImageData>[],
  transactionId?: string,
) => {
  const data: ga4Data = {
    currency: 'PLN',
    value: getItemsValue(items),
    items: items.map(mapCartItem),
  };

  if (eventName === 'purchase') {
    data.transaction_id = transactionId;
  }

  if (process.env.NODE_ENV === 'development') {
    return;
  }

  if (typeof window !== 'undefined' && window?.gtag) {
    window.gtag('event', eventName, data);
  }
};

export const reportGenerateLead = (
    formValues: formValues
) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  if (typeof window !== 'undefined' && window?.gtag) {
    window.gtag('event', 'generate_lead', {
      currency: 'PLN',
      value: 4000,
      email: formValues.email
    });
  }
};

export const reportViewItem = (value: number, items: ga4Item[]) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  if (typeof window !== 'undefined' && window?.gtag) {
    window.gtag('event', 'view_item', {
      currency: 'PLN',
      value,
      items,
    });
  }
};
