import React from 'react';
import './static/fonts/fonts.css';
import './src/styles/imports';
import { CartProvider } from './src/infrastructure/equipment/use-cart';
import { StaticImagesProvider } from './src/infrastructure/static-images/use-static-image';
import { CompareProvider } from './src/infrastructure/equipment/use-compare';

interface Props {
  element: JSX.Element;
}
export const wrapRootElement = ({ element }: Props) => (
  <StaticImagesProvider>
    <CartProvider>
      <CompareProvider>
        {element}
      </CompareProvider>
    </CartProvider>
  </StaticImagesProvider>
);

const App = ({ element }: Props) => element;

export const wrapPageElement = ({ element }: Props) => (
  <App element={element} />
);
